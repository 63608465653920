
import { useNavigate } from 'react-router-dom';

/*eslint-disable*/
function parseJwt (token:string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

const HomePage = () => {
    const navigate = useNavigate();
    var idToken = parseJwt(sessionStorage.idToken.toString());
    var accessToken = parseJwt(sessionStorage.accessToken.toString());
    console.log ("Amazon Cognito ID token encoded: " + sessionStorage.idToken.toString());
    console.log ("Amazon Cognito ID token decoded: ");
    console.log ( idToken );
    console.log ("Amazon Cognito access token encoded: " + sessionStorage.accessToken.toString());
    console.log ("Amazon Cognito access token decoded: ");
    console.log ( accessToken );
    console.log ("Amazon Cognito refresh token: ");
    console.log ( sessionStorage.refreshToken );
    console.log ("Amazon Cognito example application. Not for use in production applications.");
    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };
    /*eslint-enable*/

    return (
        <div>
            <h1>Hello World</h1>
            <p>See console log for Amazon Cognito user tokens.</p>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};
/*



VALID:

curl  -X "GET"  https://bff.fastsample.net/lambda1  -H "Accept: application/json" -H "Authorization: eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiJkYjc1OTFkMi1lNGFmLTQwN2QtYTNiYS0xNDgxY2Y5MzZmMDIiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiYzdhNTQ0YWMtMzI1Mi00ZTdkLTk5YzctNzJlZWIyNWI2MGQxIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgzNjQwOTYsImV4cCI6MTcxODM2NzY5NiwiaWF0IjoxNzE4MzY0MDk2LCJqdGkiOiIyYTc1ZDNhNi02NWM3LTQyNjctOGFkOS1hNmM0MDc2ODc1NTUiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.Pu9aBkyNxLM739Fhy-FD0KnmiF_RqW7xSzu1dPPB-mWF9CxpIXpsyw7IBqQEbxjcJ5zybwHCdWc5YDfh2TZtYW9yluvAYoLif9h36ce-CLtL2YG-CNJX2h9HdwsHVflynZndIdraUlBvAwsIPK83WZB3gMF_sgXHYuUyexgefiN_b63hwC4T3vUK-pWDrC261KMzrecQ8JLRi7bgx5brnKdfS5u1irzhAfDq06bh4PKraIZ0iiF6G_N97mJTWY0Alo0X3G-T0dpdK-w0YR60cJkc3waDsWPi1BqNX-kyVDvMV3si6e3bcnVppWU_C6yhWhzQ2YP8Sf2JhumIyuiLzA"





*
curl -X "GET" https://d3boe3pnn9vmy.cloudfront.net/v1/lambda1 -H "Accept: application/json"
*
*
*
curl -X "GET" https://uqubds28l1.execute-api.us-east-1.amazonaws.com/v1/lambda1 -H "Accept: application/json" -H "Authorization: eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiI0YmI5YjRhNi0xNzliLTQwZDktYTIzOC04ODgwNzY5MGE2N2MiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiZDQyMDE3YjgtZjg5OC00ZmVjLWFjZmEtODExYzcwYmVhNjVlIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgzNTk1MDcsImV4cCI6MTcxODM2MzEwNywiaWF0IjoxNzE4MzU5NTA3LCJqdGkiOiIwY2Y2ZWY4MC1mZmVkLTQxZWEtOWM4ZS1hZjg2MTc4NmQ1NjgiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.pPQAe8X8o_83TmxvUsw5huYAieWEc3nJDAa1LIUODCmdqdvyqbaqIOgWNdNrpj4wP2_97KLfu5iTWx-6KeFhfDCjZ8cBAr59FFiu7kBoctASzsZLIeYAKndI9z02AHzaXkBRpQ41SfUHwkuEa7JYZELrePPn_wBcq2SwW9ty4_-PIV-awnM_TfucHJF0aU5a0PUgTSRIgDsdFfIJ8fKwD3NRqxtmWBfhZzYlQKmqemsmud0Jvl97KMxoKkhVeD53OWq_9QD--8OrBrTyZ8bB1hGHinb4ICOVQrgEPyYp2uhQs6gSZzfEXW_wi8fm-BeQlG-ZeFRqKvrl25Gz7SPcQA"
*

*

*
* curl -X "GET" https://uqubds28l1.execute-api.us-east-1.amazonaws.com/v1
* curl  -X "GET"  https://bff.fastsample.net/lambda1  -H "Accept: application/json" -H "Authorization: eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiJkYjc1OTFkMi1lNGFmLTQwN2QtYTNiYS0xNDgxY2Y5MzZmMDIiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiYzdhNTQ0YWMtMzI1Mi00ZTdkLTk5YzctNzJlZWIyNWI2MGQxIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgzNjQwOTYsImV4cCI6MTcxODM2NzY5NiwiaWF0IjoxNzE4MzY0MDk2LCJqdGkiOiIyYTc1ZDNhNi02NWM3LTQyNjctOGFkOS1hNmM0MDc2ODc1NTUiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.Pu9aBkyNxLM739Fhy-FD0KnmiF_RqW7xSzu1dPPB-mWF9CxpIXpsyw7IBqQEbxjcJ5zybwHCdWc5YDfh2TZtYW9yluvAYoLif9h36ce-CLtL2YG-CNJX2h9HdwsHVflynZndIdraUlBvAwsIPK83WZB3gMF_sgXHYuUyexgefiN_b63hwC4T3vUK-pWDrC261KMzrecQ8JLRi7bgx5brnKdfS5u1irzhAfDq06bh4PKraIZ0iiF6G_N97mJTWY0Alo0X3G-T0dpdK-w0YR60cJkc3waDsWPi1BqNX-kyVDvMV3si6e3bcnVppWU_C6yhWhzQ2YP8Sf2JhumIyuiLzA"
*
*
*
* curl https://bff.fastsample.net/lambda1 -H "Accept: application/json" -H "Authorization: eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiI0YmI1MTE5Yy1mNmQ0LTQxYTktODNiOS1iN2ViZTdhMzM2MzYiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiMGYzMTdkYzYtZGZiZi00NjA0LWEyNDMtMzljMzhjNzgwN2ZhIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgyNjU3MjcsImV4cCI6MTcxODI2OTMyNywiaWF0IjoxNzE4MjY1NzI3LCJqdGkiOiJiYjI0Yzk5My0xNmMyLTRjZWYtYjMwYy1lOTUwNDFhMzVkOGYiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.VgopjDnQ9Glr3Ew6cAyREiKnJGcexaS_DsbZWAP8h1ddnrH05lE30XjtnSCbnen9q0yM8xgSc8KjLkQXWigFlwyzbXrYUvnWO-DFzYp-s_VC663rBbXP-gAco_SrknNvrsZlL4zZMKKpqritebEpyrAtKTF-QlgkBzIH6n8gR0E3pk4LaZfxaD6e1SRsB8X52OZjsAwQyFZnvO_b44Fcii7NODgn6FtL02Q9_ooRawSeehvNZVExFXEcvDN-cOLrjdMdwSy7pMlV1y0rd60XtuE2MZJt5hxZibISKXLkwekpobg3xPr5EV6_aqW-FmL_NJvPaCNeJtYaezs0cOAmtw"
curl https://bff.fastsample.net/v1/lambda1 -H "Accept: application/json" -H "Authorization: eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiI0YmI1MTE5Yy1mNmQ0LTQxYTktODNiOS1iN2ViZTdhMzM2MzYiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiMGYzMTdkYzYtZGZiZi00NjA0LWEyNDMtMzljMzhjNzgwN2ZhIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgyNjU3MjcsImV4cCI6MTcxODI2OTMyNywiaWF0IjoxNzE4MjY1NzI3LCJqdGkiOiJiYjI0Yzk5My0xNmMyLTRjZWYtYjMwYy1lOTUwNDFhMzVkOGYiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.VgopjDnQ9Glr3Ew6cAyREiKnJGcexaS_DsbZWAP8h1ddnrH05lE30XjtnSCbnen9q0yM8xgSc8KjLkQXWigFlwyzbXrYUvnWO-DFzYp-s_VC663rBbXP-gAco_SrknNvrsZlL4zZMKKpqritebEpyrAtKTF-QlgkBzIH6n8gR0E3pk4LaZfxaD6e1SRsB8X52OZjsAwQyFZnvO_b44Fcii7NODgn6FtL02Q9_ooRawSeehvNZVExFXEcvDN-cOLrjdMdwSy7pMlV1y0rd60XtuE2MZJt5hxZibISKXLkwekpobg3xPr5EV6_aqW-FmL_NJvPaCNeJtYaezs0cOAmtw"
*
curl https://uqubds28l1.execute-api.us-east-1.amazonaws.com/v1 -H "Accept: application/json" -H "Authorization: Bearer eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiI5ZWNkYmJlNS03MWIzLTQ3NzEtYjI5Yi04MmY3MDNmYzk5ZGIiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiZDI2ZDQ4YWQtM2MxYi00NzMxLTg2NDAtZGNhOTEyYWFmNjE5IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgyNjI3OTgsImV4cCI6MTcxODI2NjM5OCwiaWF0IjoxNzE4MjYyNzk4LCJqdGkiOiI5YmU1MDJkYS05NmFhLTQyOGUtYjVlMS00ZGYyNDJkZmNiNDMiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.RiJlFw6Ug37VQIwaFsTOZfcT-uo1bZBYqXTfYQ79gdrhay2XYol2R9mXQKyR34aIpXU8nMwizfQKA7VC1vxEbYemUiiJHr2U1QfYYgMzGcUQqGhyw99gq8NYy8yUSmiFnggQBSs3KtqQaM6UkG1J3n2ojAaGdUYlJhRkd9g5zwaQqa1UCNiBF27xHb7c-wt_YSVNl8T4zr4gzKCj5WJKGZ9Xp0gDHwQ8Ob8LDs-Vxwebs94IMw0zCZXom8_ODu4OA2F1mTJIG_tyE6HD4UMmDLz1iGpu0p22aYRXBzXoZE4hSdtb5UhXzym2xA4IbS6ju2_Sgw6Zt152PBEmWnamlg"
*
curl https://webhook.site/3ef14b53-3b95-4af7-b318-3c366efd8e2d -H "Accept: application/json" -H "Authorization: Bearer eyJraWQiOiIyNmhvV28zMmROOXlwTkRvYmlQMFZ6azJGbmFiN0RNeXQzOXpldWxPRU1RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NDE4MzQ3OC00MDIxLTcwYzMtN2JiMS0wNjQ4ODAxMTljYmIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNmE3WkJIVW9aIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0MTgzNDc4LTQwMjEtNzBjMy03YmIxLTA2NDg4MDExOWNiYiIsIm9yaWdpbl9qdGkiOiI5ZWNkYmJlNS03MWIzLTQ3NzEtYjI5Yi04MmY3MDNmYzk5ZGIiLCJhdWQiOiI2aG1mNWZpZHN0bjM4b3UzbDRpcTJzcWRndSIsImV2ZW50X2lkIjoiZDI2ZDQ4YWQtM2MxYi00NzMxLTg2NDAtZGNhOTEyYWFmNjE5IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTgyNjI3OTgsImV4cCI6MTcxODI2NjM5OCwiaWF0IjoxNzE4MjYyNzk4LCJqdGkiOiI5YmU1MDJkYS05NmFhLTQyOGUtYjVlMS00ZGYyNDJkZmNiNDMiLCJlbWFpbCI6ImJidXJpbS5wZXJzb25hbEBnbWFpbC5jb20ifQ.RiJlFw6Ug37VQIwaFsTOZfcT-uo1bZBYqXTfYQ79gdrhay2XYol2R9mXQKyR34aIpXU8nMwizfQKA7VC1vxEbYemUiiJHr2U1QfYYgMzGcUQqGhyw99gq8NYy8yUSmiFnggQBSs3KtqQaM6UkG1J3n2ojAaGdUYlJhRkd9g5zwaQqa1UCNiBF27xHb7c-wt_YSVNl8T4zr4gzKCj5WJKGZ9Xp0gDHwQ8Ob8LDs-Vxwebs94IMw0zCZXom8_ODu4OA2F1mTJIG_tyE6HD4UMmDLz1iGpu0p22aYRXBzXoZE4hSdtb5UhXzym2xA4IbS6ju2_Sgw6Zt152PBEmWnamlg"
*
* */
export default HomePage;