import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    SignUpCommand,
    ConfirmSignUpCommand,
    AuthFlowType
} from "@aws-sdk/client-cognito-identity-provider";
export const cognitoClient = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_COGNITO_AWS_REGION,
});

export const signIn = async (username: string, password: string) => {
    const params = {
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
    };
    try {
        const command = new InitiateAuthCommand(params);
        const { AuthenticationResult } = await cognitoClient.send(command);
        if (AuthenticationResult) {
            sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
            sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
            sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
            return AuthenticationResult;
        }
    } catch (error) {
        console.error("Error signing in: ", error);
        throw error;
    }
};

export const signUp = async (email: string, password: string) => {
    const params = {
        ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        Username: email,
        Password: password,
        UserAttributes: [
            {
                Name: "email",
                Value: email,
            },
        ],
    };
    try {
        const command = new SignUpCommand(params);
        const response = await cognitoClient.send(command);
        console.log("Sign up success: ", response);
        return response;
    } catch (error) {
        console.error("Error signing up: ", error);
        throw error;
    }
};

export const confirmSignUp = async (username: string, code: string) => {
    const params = {
        ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        Username: username,
        ConfirmationCode: code,
    };
    try {
        const command = new ConfirmSignUpCommand(params);
        await cognitoClient.send(command);
        console.log("User confirmed successfully");
        return true;
    } catch (error) {
        console.error("Error confirming sign up: ", error);
        throw error;
    }
};